import {logoSrc, mascot} from "../../images";
import React from "react";
import PaymentOptions from "../PaymentOptions";


const AppView = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const state = urlSearchParams.get('state');
    const isSuccess = state === 'success'
    return (
        <main className="flex flex-col justify-between min-h-screen bg-gray-100">
            <header className="bg-white p-4">
                <a className="flex justify-center items-center" href='https://www.collab.land'>
                    <img src={logoSrc} alt="Collab.Land"/>
                </a>
            </header>
            {isSuccess ? <section
                    className="bg-gray-100 h-3/4 p-2 flex flex-col-reverse lg:flex-row items-center gap-4 justify-center">Success</section> :
                <section
                    className="bg-gray-100 h-3/4 p-2 flex flex-col-reverse lg:flex-row items-center md:items-start gap-4 justify-between">
                    <img className=' h-[520px]' src={mascot} alt="Collab.Land Mascot"/>
                    <article className="w-screen md:max-w-xl p-5">
                        <h3 className="text-black font-semibold text-2xl mb-3">
                            We want everyone to have equal access to community, forever
                        </h3>
                        <hr className="border border-gray-400"/>
                        <p className="mt-2 leading-5">
                            At the heart of every groundbreaking idea is the belief that it can change the world. This
                            belief underpins our work at Collab.Land, where we've pioneered token gating and risen as
                            the foremost supporter of crypto communities worldwide.
                        </p>
                        <p className="mt-2 leading-5">
                            To put our ask into perspective: if just 10% of our communities chose to make a monthly
                            contribution, it would offset the infrastructure costs for over 50,000 communities. This
                            isn't just about keeping the lights on—it's about fostering an ecosystem where ideas
                            flourish and communities thrive.
                        </p>
                        <p className="mt-2 leading-5">
                            Why do people believe in and use Collab.Land? The answer lies in our commitment to
                            empowering individuals. If you've benefited from Collab.Land, you'll understand our stance -
                            every member deserves to own their data. In an era where user data often becomes a
                            commodity, we stand apart. We vow never to monetize your data, and we remain steadfast in
                            offering our platform without charging core service fees.
                        </p>
                        <p className="mt-2 leading-5">
                            So today, with a future teeming with potential, we extend an invitation: support us in a
                            manner that resonates with you. Whether that's contributing $10, $20, or $30, or simply
                            spreading the word about our mission. By doing so, you're making a statement—that a
                            non-extractive, privacy-preserving approach to social crypto and web3 community building is
                            not just vital, but the way forward.
                        </p>
                        <p className="mt-2 leading-5">
                            From all of us at Collab.Land, thank you for being a part of this journey. Together, we're
                            shaping the future of online communities.
                        </p>
                    </article>
                    <PaymentOptions/>
                </section>}

            <footer className="bg-white p-4 flex flex-row items-center justify-between">
                <div className="flex justify-between items-center gap-4 ml-auto">
                    {/*<img src={logoSrc} alt="Collab.Land"/>*/}
                    <div className="flex gap-4 flex-row">
                        <a className="text-gray-500 font-semibold" href="https://www.collab.land/privacy-policy ">
                            Privacy Policy
                        </a>
                        <a className="text-gray-500 font-semibold" href="https://www.collab.land/terms-of-service ">
                            Terms of Service
                        </a>
                        <a className="text-gray-500 font-semibold" href="https://www.collab.land">
                            ©2023 Collab.Land
                        </a>
                    </div>
                </div>
            </footer>
        </main>
    );
};

export default AppView;
